import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSelect,
  MatSelectChange,
  MatSelectTrigger,
} from '@angular/material/select';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import { LoginComponent } from 'src/app/core/components/login/login.component';
import { AuthService } from 'src/app/services/auth.service';
import { OfferService } from 'src/app/services/offer.service';
import { StorageService } from 'src/app/services/storage.service';
import { Role } from 'src/app/shared/enums/roles';
import { CardHolderEnum, OfferTypeEnum } from 'src/app/shared/enums/status';
import { IOffer } from 'src/app/shared/model/offer.model';
import { FilterService } from 'primeng/api';
import { ResponsiveService } from 'src/app/shared/utils/responsive.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('categorySelect') categorySelect!: MatSelect;
  tabs = [
    { title: 'Welcome Offer', value: OfferTypeEnum.WELCOME },
    { title: 'Partners Offer', value: OfferTypeEnum.PARTNER },
    { title: 'VISA Offer', value: OfferTypeEnum.VISA },
  ];

  tabsVisa = [
    {
      title: 'For International Cardholder',
      value: CardHolderEnum.INTER,
    },
    {
      title: 'For Thai Cardholder',
      value: CardHolderEnum.THAI,
    },
  ];

  images = [
    '../assets/Logo_Partner/VISA.png',
    '../assets/Logo_Partner/Tceb.png',
  ];

  imagesStrategic = [
    '../assets/Logo_Partner/central_new.png',
    '../assets/Logo_Partner/centara_new.png',
    '../assets/Logo_Partner/robinson_new.png',
    '../assets/Logo_Partner/T2P_new.png',
  ];

  imagesPartner = [
    '../assets/Logo_Partner/Bitec.png',
    '../assets/Logo_Partner/Impact.png',
    '../assets/Logo_Partner/QSNCC.png',
    '../assets/Logo_Partner/TEA.png',
    '../assets/Logo_Partner/Aleenta.png',
    '../assets/Logo_Partner/adlib.png',
    '../assets/Logo_Partner/buri.jpg',
    '../assets/Logo_Partner/monkam.png',
  ];

  selectedTab = 0;
  selectedTabVisa = 0;
  isLoading = false;
  pagination = false;
  subscription!: Subscription;
  isUser = false;
  currentPage = 1;
  pageSize: number = 50;
  totalPages: number | undefined;
  totalCount: number | undefined;
  offerList: IOffer[] = [];
  filterOffer!: IOffer[];
  redeemEnd: any;
  role!: Role;
  screenW: any;

  filterList: string[] = [
    'DINING',
    'SHOPPING',
    'HEALTH&SPA',
    'AIRPORT LUGGAGE',
    'TRANSPORTATION',
    'LOGISTIC',
    'EDUCATION',
    'ACCOMMODATION',
    'ACTIVITY',
    'MEETING ROOM',
  ];

  filterListPartner: string[] = [
    'DINING',
    'ACCOMMODATION',
    'ACTIVITY',
    'MEETING ROOM',
    'SHOPPING',
    'EDUCATION',
    'HEALTH&SPA',
  ];
  filterListInter: string[] = [
    'AIRPORT LUGGAGE',
    'DINING',
    'SHOPPING',
    'LOGISTIC',
    'TRANSPORTATION',
    'HEALTH&SPA',
  ];
  filterListThai: string[] = [
    'AIRPORT LUGGAGE',
    'LOGISTIC',
    'TRANSPORTATION',
    'HEALTH&SPA',
  ];

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    offerType: this.tabs[this.selectedTab].value,
    cardHolder: null,
    sortBy: 'merchantNameEN',
    sortOrderBy: 'asc',
  };

  filterArray: any[] = [];
  private filterSubject = new BehaviorSubject<string[]>([]); // Stream for filter changes
  filterSub!: Subscription;

  constructor(
    public matDialog: MatDialog,
    private authService: AuthService,
    private offerService: OfferService,
    private router: Router,
    private storageService: StorageService,
    private filterService: FilterService,
    private responsiveService: ResponsiveService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const width = this.document.defaultView?.innerWidth;
    this.screenW = width! < 640 ? 1 : 2;
  }

  remove(category: string): void {
    const index = this.filterArray.indexOf(category);

    if (index >= 0) {
      this.filterArray.splice(index, 1);

      // Manually uncheck the option
      if (this.categorySelect) {
        this.categorySelect.options.forEach((option: MatOption) => {
          if (option.value === category) {
            option.deselect(); // Deselect the corresponding option
          }
        });
      }
    }
  }

  ngOnInit(): void {
    this.getOffer(false);
    this.subscription = this.authService.currentUser.subscribe((user) => {
      this.isUser = Boolean(user);
      this.role = user?.roles;
      this.getRedeemEnd();
    });
    this.filterSubjectFn();

    const sub2 = this.storageService.changes.subscribe((data) => {
      if (data.key === 'profile') {
        this.getRedeemEnd(data.value);
      }
    });

    const sub3 = this.responsiveService.screenWidth$.subscribe((width) => {
      this.screenW = width < 640 ? 1 : 2;
    });

    this.subscription.add(sub2);
    this.subscription.add(sub3);
  }

  filterSubjectFn() {
    this.filterSub = this.filterSubject
      .pipe(
        debounceTime(300) // Wait 300ms after each input
      )
      .subscribe((filters: string[]) => {
        if (filters && filters.length > 0) {
          this.filterOffer = this.offerList?.filter((item: IOffer) =>
            this.filterService.filters['in'](
              item.category?.toLocaleUpperCase(),
              filters
            )
          );
        } else {
          this.filterOffer = [...this.offerList];
        }

        this.isLoading = false;
      });
  }

  getRedeemEnd(data?: any) {
    if (!Boolean(this.redeemEnd)) {
      const profile = localStorage.getItem('profile');

      if (profile) {
        const parseProfile = JSON.parse(profile);
        const date: any = new Date(parseProfile.createdAt);
        this.redeemEnd = new Date(date);
        this.redeemEnd.setMonth(this.redeemEnd.getMonth() + 3);
      } else if (Boolean(data)) {
        const date = new Date(data.createdAt);
        this.redeemEnd = new Date(date);
        this.redeemEnd.setMonth(this.redeemEnd.getMonth() + 3);
      }
    }
  }

  sortBy(sortType: string) {
    if (sortType === this.queryParams.sortBy) {
      if (this.queryParams.sortOrderBy === 'asc') {
        this.queryParams.sortOrderBy = 'desc';
      } else {
        this.queryParams.sortOrderBy = 'asc';
      }
    } else {
      this.queryParams.sortBy = sortType;
      this.queryParams.sortOrderBy = 'asc';
    }
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.queryParams.skip = this.currentPage;
    }
  }

  filterCategory(value: string[]) {
    // if (value.length > 0) {
    //   this.filterOffer = this.offerList.filter((item: IOffer) =>
    //     this.filterService.filters['in'](
    //       item.category?.toLocaleUpperCase(),
    //       value
    //     )
    //   );
    // } else {
    //   this.filterOffer = [...this.offerList];
    // }

    this.isLoading = true;
    this.filterSubject.next(value);
  }

  getOffer(isPagination: boolean) {
    this.isLoading = true;
    if (!isPagination) {
      this.currentPage = 1;
      this.queryParams.skip = this.currentPage;
    } else {
      this.pagination = true;
    }

    this.offerService
      .getOfferByType(this.queryParams)
      //pinned data feature on hold
      // .pipe(
      //   map((data: any) => {
      //     if (data.data.totalData.length > 0) {
      //       // pinned any merchant as business requirement
      //       const offer: IOffer[] = [...data.data.totalData];

      //       const pinned: IOffer[] = offer.filter((offer: IOffer) =>
      //         this.pinnedMerchant.some(
      //           (item: any) => offer.merchantNameEN === item
      //         )
      //       );

      //       const nonPinned: IOffer[] = offer.filter(
      //         (offer: IOffer) =>
      //           !this.pinnedMerchant.some(
      //             (item: any) => offer.merchantNameEN === item
      //           )
      //       );
      //       const offerWithPinned = [...pinned, ...nonPinned];
      //       data.data.totalData = [...offerWithPinned];
      //     }

      //     return data;
      //   })
      // )
      .subscribe({
        next: (data: any) => {
          this.totalCount = data.data.totalCount;

          if (isPagination) {
            if (this.offerList.length > 0) {
              this.offerList = [...this.offerList, ...data.data.totalData];
            } else {
              this.offerList = data.data.totalData;
            }
          } else {
            this.offerList = data.data.totalData;
          }

          this.filterCategory(this.filterArray);
          this.pagination = false;
        },
        error: (err) => {
          console.log(err);
          this.isLoading = false;
          this.pagination = false;
        },
      });
    // if (
    //   (this.isUser && this.role === 'USER') ||
    //   ((!this.isUser || this.role !== 'USER') &&
    //     this.queryParams.offerType !== OfferTypeEnum.WELCOME)
    // ) {
    //   if (!isPagination) {
    //     this.currentPage = 1;
    //     this.queryParams.skip = this.currentPage;
    //   } else {
    //     this.pagination = true;
    //   }

    //   this.offerService.getOfferByType(this.queryParams).subscribe({
    //     next: (data: any) => {
    //       this.totalCount = data.data.totalCount;

    //       if (isPagination) {
    //         if (this.offerList.length > 0) {
    //           this.offerList = [...this.offerList, ...data.data.totalData];
    //         } else {
    //           this.offerList = data.data.totalData;
    //         }
    //       } else {
    //         this.offerList = data.data.totalData;
    //       }

    //       this.filterCategory(this.filterArray);
    //       this.pagination = false;
    //     },
    //     error: (err) => {
    //       console.log(err);
    //       this.isLoading = false;
    //       this.pagination = false;
    //     },
    //   });
    // } else {
    //   this.isLoading = false;
    // }
  }

  onSelectCardHolder(event: any) {
    this.filterArray = [];

    this.getOffer(false);
  }

  onSelectTab(index: number, topic: string) {
    this.filterArray = [];
    if (topic === 'main') {
      const tempObj = { ...this.queryParams };
      const previousTab = tempObj.offerType;
      this.selectedTab = index;
      this.queryParams.offerType = this.tabs[this.selectedTab].value;
      if (previousTab !== this.queryParams.offerType) {
        if (this.tabs[this.selectedTab].value === OfferTypeEnum.VISA) {
          if (
            this.tabsVisa[this.selectedTabVisa].value === CardHolderEnum.THAI
          ) {
            this.queryParams.cardHolder = CardHolderEnum.THAI;
          } else {
            this.queryParams.cardHolder = CardHolderEnum.INTER;
          }
        } else {
          this.queryParams.cardHolder = null;
        }
        this.getOffer(false);
      }
    } else {
      const tempObjVisa = { ...this.queryParams };
      const previousTabVisa = tempObjVisa.cardHolder;
      this.selectedTabVisa = index;
      this.queryParams.cardHolder = this.tabsVisa[this.selectedTabVisa].value;
      if (previousTabVisa !== this.queryParams.cardHolder) {
        this.getOffer(false);
      }
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  onScroll = () => {
    if (this.offerList && this.totalCount && !this.isLoading) {
      if (this.offerList?.length < this.totalCount!) {
        this.currentPage++;
        this.queryParams.skip = this.currentPage;
        this.getOffer(true);
      }
    }
  };

  onOpenLoginDialog() {
    const optionSM = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      minWidth: '100%',
    };
    const option = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '480px',
    };

    const selectOption = this.screenW === 1 ? optionSM : option;

    const dialogRef = this.matDialog.open(LoginComponent, selectOption);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.filterSub.unsubscribe();
  }
}
